// transactionUtils.js

export const calculateTotalTransactions = (transactions) => transactions.length;

export const calculateTransactionsThisYear = (transactions) => {
  const currentYear = new Date().getFullYear();
  return transactions.filter((tx) => new Date(tx.timestamp).getFullYear() === currentYear).length;
};

export const findSeedingWallet = (transactions) => {
  const sortedTransactions = transactions.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  return sortedTransactions.length > 0 ? sortedTransactions[0].counterAddress : 'Unknown';
};

export const calculateTotalInteractedAddresses = (transactions) => {
  const addresses = new Set(transactions.map((tx) => tx.counterAddress).concat(transactions.map((tx) => tx.address)));
  return addresses.size;
};

export const calculateWalletMostInteractedWith = (transactions) => {
  const interactionMap = new Map();
  transactions.forEach((tx) => {
    interactionMap.set(tx.counterAddress, (interactionMap.get(tx.address) || 0) + 1);
    interactionMap.set(tx.counterAddress, (interactionMap.get(tx.address) || 0) + 1);
  });
  let mostInteracted = { address: '', interactions: 0 };
  interactionMap.forEach((interactions, address) => {
    if (interactions > mostInteracted.interactions) {
      mostInteracted = { address, interactions };
    }
  });
  return mostInteracted.address;
};

export const calculateTotalIncomingTransactions = (transactions, currentAddress) => {
  return transactions.filter((tx) => tx.transactionSubtype === "incoming").length;
};
