// Button Element
// import styled, { keyframes } from 'styled-components';
import styled from 'styled-components';

// Rotate shadow keyframes
// const rotateShadow = keyframes`
//   0% {
//     transform: rotate(0deg);
//   }
//   100% {
//     transform: rotate(360deg);
//   }
// `;

// Define keyframes for the animation
// const expandAnimation = keyframes`
//   0% {
//     transform: scale(1);
//     z-index: 3;
//   }
//   100% {
//     z-index: 3;
//     transform: scale(4); /* You can adjust the final scale factor */
//   }
// `;

// Degen Button - Soft Black Neumorphic Design
export const DegenButton = styled.div`
  position: relative;
  width: 30vw;
  height: 30vw;
  border-radius: 50%;
  background: #000; // Black background for DegenButton
  box-shadow: 
    8px 8px 15px #1e1e1e, // darker shadow
    -8px -8px 15px #404040; // lighter shadow
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  overflow: hidden;
  transition: transform 0.5s ease-in-out; /* Smooth transition */

  /* Hover animation */
  &:hover {
    z-index: 3;
    transform: scale(4); /* Slightly increase the scale on hover */
  }
`;


// Human Button - White Neumorphic Design
export const HumanButton = styled.div`
  position: relative;
  width: 30vw;
  height: 30vw;
  border-radius: 50%;
  background: #FFF; // White background for HumanButton
  box-shadow: 
    8px 8px 15px #becde2, // darker shadow
    -8px -8px 15px #ffffff; // lighter shadow
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 20px;
  overflow: hidden;
  transition: transform 0.5s ease-in-out; /* Smooth transition */

  /* Hover animation */
  &:hover {
    transform: scale(4); /* Slightly increase the scale on hover */
  }
`;

// Static Text Label
export const ButtonLabel = styled.span`
  position: absolute;
  font-size: 24px;
  font-weight: bold;
  color: ${({ isDegen }) => (isDegen ? '#FFFFFF' : '#303030')}; // DEGEN label is light, HUMAN label is dark
  user-select: none;
  z-index: 1;
`;
