import React, { useState } from 'react';
import Panel from '../components/styled/Panel';
import { DegenButton, HumanButton, ButtonLabel } from '../components/styled/ButtonElement';
// Replace useHistory with useNavigate
import { useNavigate } from 'react-router-dom';
import { TatumSDK, Network, MetaMask } from '@tatumio/tatum';

const ChoiceScreen = () => {
    // Replace useHistory with useNavigate
    const navigate = useNavigate();
    const [isHumanExpanded, setIsHumanExpanded] = useState(false);
    const [isDegenExpanded, setIsDegenExpanded] = useState(false);
    const [humanAddress, setHumanAddress] = useState('0xCAE83F10d87C0Ac550A86dA8eB4b786085135447');

    const handleHumanExpand = () => {
        setIsHumanExpanded(true);
    };

    const handleHumanCollapse = () => {
        setIsHumanExpanded(false);
    };

    const handleDegenExpand = () => {
        setIsDegenExpanded(true);
    };

    const handleDegenCollapse = () => {
        setIsDegenExpanded(false);
    };

    const connectToMetaMask = async () => {
        try {
            // Your Tatum SDK logic here
            const tatum = await TatumSDK.init({ network: Network.ETHEREUM });
            const degenAddress = await tatum.walletProvider.use(MetaMask).getWallet();
            console.log(degenAddress);
            navigate(`/portfolio/${degenAddress}`);
            // alert('Connect to MetaMask functionality goes here');
        } catch (error) {
            console.error('Error connecting to MetaMask:', error);
        }
    };

    const handleInputSubmit = (event) => {
        if (event.key === 'Enter') {
            // Use navigate instead of history.push
            navigate(`/portfolio/${humanAddress}`);
        }
    };

    return (
        <Panel>
            <DegenButton
                onMouseEnter={handleDegenExpand}
                onMouseLeave={handleDegenCollapse}
                onClick={connectToMetaMask}
            >
                {isDegenExpanded ? (
                    <div>
                        <ButtonLabel style={{ marginTop: '-25px', color: '#FFF' }}>DEGEN</ButtonLabel>
                        <button onClick={connectToMetaMask}>Connect to Metamask</button>
                    </div>
                ) : (
                    <ButtonLabel isDegen={true}>DEGEN</ButtonLabel>
                )}
            </DegenButton>
            <HumanButton
                onMouseEnter={handleHumanExpand}
                onMouseLeave={handleHumanCollapse}
            >
                {isHumanExpanded ? (
                    <div>
                        <ButtonLabel style={{ marginTop: '-25px' }}>Human</ButtonLabel>
                        <input
                            type="text"
                            placeholder="0xCAE83F10d87C0Ac550A86dA8eB4b786085135447"
                            value={humanAddress}
                            onChange={(e) => setHumanAddress(e.target.value)}
                            onKeyPress={handleInputSubmit}
                        />
                    </div>
                ) : (
                    <ButtonLabel>Human</ButtonLabel>
                )}
            </HumanButton>
        </Panel>
    );
};

export default ChoiceScreen;