import { TatumSDK, Network } from '@tatumio/tatum';

class TatumHandler {
  constructor(network = Network.ETHEREUM) {
    this.tatumPromise = TatumSDK.init({
      network: network,
      apiKey: process.env.TATUM_API_KEY,
    });
  }

  async fetchAllTransactions(address, callback) {
    let page = 0;
    let hasMore = true;
    let tatum;

    try {
      tatum = await this.tatumPromise;
    } catch (error) {
      console.error("Error initializing Tatum SDK:", error);
      return;
    }

    while (hasMore) {
      try {
        if (tatum && typeof tatum.address.getTransactions === 'function') {
          const response = await tatum.address.getTransactions({ address, pageSize: 50, page});
          const transactions = response.data;
          if (transactions.length === 0) {
            hasMore = false;
          } else {
            callback(transactions); // Call the callback function with the new transactions
            page += 1;
          }
        } else {
          throw new Error('Tatum instance is not correctly initialized or getTransactions is not available.');
        }
      } catch (error) {
        console.error("Error fetching transactions:", error);
        hasMore = false;
      }
    }
  }
}

export default TatumHandler;
