// TransactionTable.js
import React from 'react';
import TransactionItem, { TransactionTable, TransactionRow, TransactionHeader } from './TransactionItem'; // Import without renaming

const TransactionTableComponent = ({ transactions }) => {
  return (
      <TransactionTable>
        <thead>
          <TransactionRow>
            <TransactionHeader>Type</TransactionHeader>
            <TransactionHeader>Hash</TransactionHeader>
            <TransactionHeader>Counter Address</TransactionHeader>
            <TransactionHeader>Amount</TransactionHeader>
            <TransactionHeader>Timestamp</TransactionHeader>
          </TransactionRow>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <TransactionItem key={index} transaction={transaction} />
          ))}
        </tbody>
      </TransactionTable>
  );
};

export default TransactionTableComponent;
