// TransactionItem.js
import React from 'react';
import styled from 'styled-components';


// Define your colors with similar tones to the inspiration image
const backgroundColor = '#0E0E0E'; // Dark background for the page
const elementBackground = '#202121'; // Slightly lighter background for the elements
const accentColor = '#8abdff'; // Blue accent color

// Shadow and highlight colors for the neumorphic effect
const shadowDark = 'rgba(0, 0, 0, 0.5)';
const shadowLight = 'rgba(255, 255, 255, 0.1)';

export const PageContainer = styled.div`
  padding: 20px;
  background-color: ${backgroundColor};
  font-family: 'Anta', sans-serif;
`;

export const TransactionTable = styled.table`
  width: 80%;
  max-width: 80%;
  margin: 20px auto;
  background: ${elementBackground};
  border-collapse: separate;
  border-spacing: 0 15px;
  border-radius: 25px; // More pronounced rounded corners
  box-shadow: 
    5px 5px 10px ${shadowDark},
    -5px -5px 10px ${shadowLight};
`;

export const TransactionRow = styled.tr`
  &:nth-child(even) {
    background-color: ${elementBackground}; // Keep rows consistent with the table background
  }
  &:hover {
    background-color: ${accentColor}; // Highlight row on hover with the accent color
  }
`;

export const TransactionHeader = styled.th`
  padding: 16px 8px;
  text-align: left;
  background: ${elementBackground}; // Same as the element background
  color: white;
  border-radius: 15px; // Rounded corners for headers
`;

const TransactionData = styled.td`
  padding: 16px 8px;
  color: white;
  background: ${elementBackground};
  &:hover {
    background: linear-gradient(145deg, ${shadowLight}, ${shadowDark});
  }
  a {
    color: #fff; // Links are white by default
    text-decoration: underline; // Underline the links by default
    &:hover {
      color: #bbdefb; // Very light blue on hover
      text-decoration: none; // Optionally remove the underline on hover
    }
  }
`;


// Your existing styled components...

const formatString = (str) => {
    return `${str.slice(0, 3)}...${str.slice(-3)}`;
};

const TransactionItem = ({ transaction }) => {
    // Determine the transaction subtype text based on the direction
    const transactionSubtype = transaction.transactionSubtype === 'outgoing' ? 'Sent' : 'Recieved';

    return (
        <TransactionRow>
            <TransactionData>{transactionSubtype}</TransactionData> {/* Updated to use transactionSubtype */}
            <TransactionData>
                <a href={`https://etherscan.io/tx/${transaction.hash}`} target="_blank" rel="noopener noreferrer">
                    {formatString(transaction.hash)}
                </a>
            </TransactionData>
            <TransactionData>
                <a href={`https://etherscan.io/address/${transaction.counterAddress}`} target="_blank" rel="noopener noreferrer">
                    {formatString(transaction.counterAddress)}
                </a>
            </TransactionData>
            <TransactionData>{transaction.amount}</TransactionData>
            <TransactionData>{new Date(transaction.timestamp).toLocaleString()}</TransactionData>
        </TransactionRow>
    );
};

export default TransactionItem;
