import React from 'react';
import {
  calculateTotalTransactions,
  calculateTransactionsThisYear,
  findSeedingWallet,
  calculateTotalInteractedAddresses,
  calculateWalletMostInteractedWith,
  calculateTotalIncomingTransactions
} from '../utils/TransactionsUtils';
import { Card, CardTitle, CardValue, CardContainer } from './styled/Cards';

function Cards({ transactions }) {
  // Derived state from the transactions array
  const totalTransactions = calculateTotalTransactions(transactions);
  const totalTransactionsThisYear = calculateTransactionsThisYear(transactions);
  const seedingWallet = findSeedingWallet(transactions);
  const totalInteractedAddresses = calculateTotalInteractedAddresses(transactions);
  const walletMostInteractedWith = calculateWalletMostInteractedWith(transactions);
  const totalIncomingTransactions = calculateTotalIncomingTransactions(transactions);


  const formatString = (str) => {
    return `${str.slice(0, 5)}...${str.slice(-5)}`;
  };

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
      <CardContainer>
        <Card>
          <CardTitle>Total Transactions</CardTitle>
          <CardValue>{totalTransactions}</CardValue>
        </Card>
        <Card>
          <CardTitle>Transactions This Year</CardTitle>
          <CardValue>{totalTransactionsThisYear}</CardValue>
        </Card>
        <Card>
          <CardTitle>Seeding Wallet</CardTitle>
          <CardValue><a href={`https://etherscan.io/address/${seedingWallet}`} target="_blank" rel="noopener noreferrer">
            {formatString(seedingWallet)}
          </a></CardValue>
        </Card>
        <Card>
          <CardTitle>Total Addresses Interacted With</CardTitle>
          <CardValue>{totalInteractedAddresses}</CardValue>
        </Card>
        <Card>
          <CardTitle>Wallet Most Interacted With</CardTitle>
          <CardValue><a href={`https://etherscan.io/address/${walletMostInteractedWith}`} target="_blank" rel="noopener noreferrer">
            {formatString(walletMostInteractedWith)}
          </a></CardValue>
        </Card>
        <Card>
          <CardTitle>Total Incoming Transactions</CardTitle>
          <CardValue>{totalIncomingTransactions}</CardValue>
        </Card>
      </CardContainer>
    </div>
  )
}

export default Cards