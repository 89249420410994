// Portfolio.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TatumHandler from '../services/TatumHandler';
import TransactionTable from '../components/TransactionTable';
import {PageContainer} from '../components/TransactionItem';
import Cards from '../components/Cards';

const Portfolio = () => {
  const { address } = useParams();
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const tatumHandler = new TatumHandler();
    const updateTransactions = (newTransactions) => {
      setTransactions((prevTransactions) => [...prevTransactions, ...newTransactions]);
    };

    if (address) {
      tatumHandler.fetchAllTransactions(address, updateTransactions);
    }
  }, [address]);

  return (
    <div>
      <PageContainer>
        <Cards transactions={JSON.parse(JSON.stringify(transactions))} >
        </Cards>
        <TransactionTable transactions={transactions} />
      </PageContainer> 
    </div>
  );
};

export default Portfolio;
