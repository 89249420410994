import styled from 'styled-components';

const Panel = styled.div`
  display: flex;
  justify-content: center; // Center children horizontally
  align-items: center; // Center children vertically
  // border-radius: 25px; // Smoothed corners
  margin: auto;
  width: 100vw; // Full view width
  height: 100vh; // Full view height
  background: #E0EFFF; // Soft background color
  box-shadow: 
    inset 4px 4px 6px #becde2, // Inner top shadow for depth
    inset -4px -4px 6px #ffffff; // Inner bottom shadow for lift effect
  flex-direction: column; // Stack children vertically

  @media (min-width: 600px) {
    flex-direction: row; // On larger screens, buttons side by side
    padding: 2em; // Add some padding for spacing
  }

  transition: all 0.3s ease-in-out;
`;

export default Panel;
